const namespaced = true;

import AvailableConnectorsApi from "@js/api/available_connectors";

const state = {
  opposedConnectorList: [],
};

const mutations = {
  opposedConnectorList(state) {
    if (state.opposedConnectorList.length != 0) {
      // cache
      return state.opposedConnectorList;
    }

    let result = [];

    AvailableConnectorsApi.port_available_connectors().then((value) => {
      if (value != null) {
        state.opposedConnectorList = value;
      }
    });

    state.opposedConnectorList = result;
  },
};

export default {
  namespaced,
  state,
  mutations,
};
