import client from "./client";

const port_available_connectors = async function () {
  let resource = "/ports/available_connectors.json";

  let data = null;

  data = await client
    .get(resource)
    .then((response) => {
      return response.data["available_connectors"];
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export default { port_available_connectors };
